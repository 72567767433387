<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps({
  modelValue: {
    type: Number,
    default: 1
  },
  total: {
    type: Number,
    default: 1
  },
  maxVisible: {
    type: Number,
    default: 3
  },
  scrollTarget: {
    type: Object,
    default: undefined
  }
})
const emit = defineEmits(['update:modelValue'])
function handlePageChange(page: number) {
  emit('update:modelValue', page)
  window.scrollTo({
    top: !props.scrollTarget ? 0 : (props.scrollTarget as HTMLElement).offsetTop,
    behavior: 'smooth'
  })
}

const showingMin = computed(() => {
  return Math.min(
    Math.max(1, props.modelValue - Math.floor(props.maxVisible / 2)),
    Math.max(1, props.total - props.maxVisible + 1)
  )
})
const showingMax = computed(() => {
  return Math.min(showingMin.value + props.maxVisible - 1, props.total)
})
const showingPages = computed(() => {
  return Array.from(
    { length: showingMax.value - showingMin.value + 1 },
    (_, i) => i + showingMin.value
  )
})
</script>

<template>
  <ol class="text-xs flex items-center justify-center gap-1 font-light">
    <li>
      <button
        type="button"
        aria-label="Prev Page"
        :disabled="props.modelValue === 1"
        class="inline-flex h-8 w-8 items-center justify-center rounded-card border border-gray-30 bg-white text-gray-60 disabled:text-gray-30 rtl:rotate-180 dark:bg-gray-60 dark:text-white dark:disabled:bg-gray-20 dark:disabled:text-gray-50"
        @click="handlePageChange(props.modelValue - 1)"
      >
        <span class="sr-only">Prev Page</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </li>
    <li v-if="!showingPages.includes(1)">
      <button
        type="button"
        aria-label="page button 1"
        class="block h-8 w-8 rounded-card border border-gray-30 bg-white text-center leading-8 text-gray-60 dark:bg-gray-60 dark:text-white"
        @click="handlePageChange(1)"
      >
        1
      </button>
    </li>
    <li v-if="!showingPages.includes(1)" class="select-none">...</li>

    <li v-for="page in showingPages" :key="page">
      <button
        type="button"
        :aria-label="'page button' + page"
        :class="{
          'block h-8 w-8 rounded-card border border-gray-30 bg-white text-center leading-8 text-gray-60 dark:bg-gray-60 dark:text-white': true,
          '!bg-primary !text-white': page === props.modelValue
        }"
        @click="handlePageChange(page)"
      >
        {{ page }}
      </button>
    </li>
    <li v-if="!showingPages.includes(props.total)" class="select-none">...</li>
    <li v-if="!showingPages.includes(props.total)">
      <button
        type="button"
        :aria-label="'page button' + props.total"
        class="block h-8 w-8 rounded-card border border-gray-30 bg-white text-center leading-8 text-gray-60 dark:bg-gray-60 dark:text-white"
        @click="handlePageChange(props.total)"
      >
        {{ props.total }}
      </button>
    </li>

    <li>
      <button
        type="button"
        aria-label="Next Page"
        :disabled="props.modelValue === props.total"
        class="inline-flex h-8 w-8 items-center justify-center rounded-card border border-gray-30 bg-white text-gray-60 disabled:text-gray-30 rtl:rotate-180 dark:bg-gray-60 dark:text-white dark:disabled:bg-gray-20 dark:disabled:text-gray-50"
        @click="handlePageChange(props.modelValue + 1)"
      >
        <span class="sr-only">Next Page</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </li>
  </ol>
</template>
